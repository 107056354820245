
import { computed, ref } from '@vue/reactivity';
import {
  ChevronRightIcon,
  CheckCircleIcon,
  XCircleIcon,
} from '@heroicons/vue/solid';
import { getAllAvailableContentPermissions } from '@/api/integrations.api';
import SystemStatusBadge from '@/components/SystemStatusBadge.vue';
import toCamelCase from '@/utils/toCamelCase';

export default {
  name: 'support',
  components: {
    ChevronRightIcon,
    CheckCircleIcon,
    XCircleIcon,
    SystemStatusBadge,
  },
  setup() {
    const permissions = ref({} as any);
    const permissionsError = ref('');
    const permissionsLoading = ref(false);
    const searchQuery = ref('');

    const fetchPermissions = async () => {
      permissionsLoading.value = true;
      permissionsError.value = '';
      try {
        const response = await getAllAvailableContentPermissions();
        const transformedPermissions: { [id: string]: any } = {};
        for (let i = 0; i < response.data.permissions.length; i++) {
          const permission = response.data.permissions[i];
          if (!(permission.airlineId in transformedPermissions))
            transformedPermissions[permission.airlineId] = {};
          transformedPermissions[permission.airlineId][permission.productType] =
            {
              id: permission.id,
              airlineId: permission.airlineId,
              enabled: permission.enabled,
              partnerEnabled: permission.partnerEnabled,
            };
        }
        permissions.value = toCamelCase(transformedPermissions);
      } catch (e) {
        permissions.value = [];
        permissionsError.value = `Error fetching permissions`;
      }
      permissionsLoading.value = false;
    };

    const filteredPermissions = computed(() => {
      if (searchQuery.value && searchQuery.value.length == 2) {
        return Object.keys(permissions.value)
          .filter((key) => key == searchQuery.value.toUpperCase())
          .reduce((obj: any, key: string) => {
            obj[key] = permissions.value[key];
            return obj;
          }, {});
      } else {
        return permissions.value;
      }
    });

    fetchPermissions();

    return {
      searchQuery,
      permissions,
      permissionsLoading,
      permissionsError,
      filteredPermissions,
    };
  },
};
